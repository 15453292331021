<template>
    <div class="pt-7 container-fluid">
        <b-card>
            <b-row>
                <b-col md="4">
                    <base-input type="text" :label="$t('general.search_area')" :placeholder="$t('general.search_area')" v-model="filter.title" @input="searchTimeOut">
                    </base-input>
                </b-col>
                <b-col md="4">
                    <base-input :label="$t('general.currency')" name="currency" :nameAs="$t('general.currency')" :rules="'required'">
                        <el-select v-model="filter.currency" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" required @change="getData">
                            <el-option v-for="(item, key) in currencyList" :key="key" :label="item.toUpperCase()" :value="item"></el-option>
                        </el-select>
                    </base-input>
                </b-col>
                <b-col md="4" class="text-right">
                    <base-button type="success" @click="addNew" class="my--5">
                        <i class="fas fa-plus"></i> {{$t('general.addnew')}}
                    </base-button>
                </b-col>
            </b-row>
        </b-card>
        <b-card no-body class="p-4">
            <el-table class="table-responsive table" header-row-class-name="thead-light" :data="banks" v-loading='loading' style="width: 100%" :empty-text="$t('general.empty_table')" :row-class-name="tableRowClassName">
                <el-table-column :label="$t('bank.name')" prop="name" min-width="170">
                </el-table-column>
                <el-table-column :label="$t('bank.branch')" prop="branch" min-width="170">
                </el-table-column>
                <el-table-column :label="$t('bank.iban')" prop="iban" min-width="200">
                </el-table-column>
                <el-table-column :label="$t('general.currency')" prop="currency" min-width="150">
                    <template v-slot="{row}">
                        {{row.currency.toUpperCase()}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.created_date')" prop="createdAt" min-width="170">
                    <template v-slot="{row}">
                        {{row.createdAt | getDate}}
                    </template>
                </el-table-column>
                <el-table-column align="right" min-width="180">
                    <template slot-scope="scope">
                        <base-button type="primary" size="sm" @click="handleEdit(scope.row)">
                            {{$t('general.edit')}}</base-button>
                        <base-button type="danger" size="sm" @click="handleDelete(scope.row.id)">{{$t('general.delete')}}</base-button>
                    </template>
                </el-table-column>
            </el-table>
        </b-card>
        <modal :show.sync="modal.status" :showClose="false" :backdropClose="false" :hideFooter="true" @close="modal.status=false" size="lg">
            <validation-observer v-slot="{handleSubmit, invalid}" ref="formValidator">
                <h6 slot="header" class="modal-title">{{modal.data.id ? $t('bank.edit') : $t('bank.add')}}</h6>
                <hr class="my-2">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)" v-loading="modal.loading">
                    <b-row>
                        <b-col md="3">
                            <label class="form-control-label">{{$t('general.status')}}</label>
                            <b-form-checkbox v-model="modal.data.status" class="switchCheck dblock" name="check-button" switch>
                                {{modal.data.status ? $t('general.active') : $t('general.passive')}}
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="3">
                            <base-input type="text" name="name" :nameAs="$t('bank.name')" :label="$t('bank.name')" :placeholder="$t('bank.name')" v-model="modal.data.name" required>
                            </base-input>
                        </b-col>
                        <b-col md="3">
                            <base-input type="text" name="branch" :nameAs="$t('bank.branch')" :label="$t('bank.branch')" :placeholder="$t('bank.branch')" v-model="modal.data.branch">
                            </base-input>
                        </b-col>
                        <b-col md="10">
                            <base-input type="text" name="iban" :nameAs="$t('bank.iban')" :label="$t('bank.iban')" :placeholder="$t('bank.iban')" v-model="modal.data.iban" required>
                            </base-input>
                        </b-col>
                        <b-col md="2">
                            <base-input :label="$t('general.currency')" name="currency" :nameAs="$t('general.currency')" :rules="'required'">
                                <el-select v-model="modal.data.currency" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" required>
                                    <el-option v-for="(item, key) in currencyList" :key="key" :label="item.toUpperCase()" :value="item"></el-option>
                                </el-select>
                            </base-input>
                        </b-col>
                    </b-row>
                    <hr class="my-2">
                    <b-alert show variant="warning" v-if="invalid">
                        <strong>{{$t('warning.fill_title')}} : </strong> {{$t('warning.fill')}}
                    </b-alert>
                    <base-button type="primary" native-type="submit" :disabled="modal.loading || invalid">{{$t('save')}}</base-button>
                    <base-button type="link text-danger" class="ml-auto float-right" @click="modal.status = false" :disabled="modal.loading">{{$t('close')}}</base-button>
                </b-form>
            </validation-observer>
        </modal>
    </div>
</template>
<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { BANK_REQUEST, BANK_UPDATE, BANK_ADD, BANK_DELETE } from '@/store/modules/define';
import { handleError } from '@/utils/func';
let self;
let emptyData = {
    id: false,
    name: '',
    branch: '',
    currency: '',
    iban: '',
    status: true
};

export default {
    data() {
        return {
            currencyList: ['try', 'usd', 'eur', 'gbp'],
            timer: null,
            loading: false,
            modal: {
                status: false,
                loading: false,
                data: _.cloneDeep(emptyData)
            },
            status: {
                true: 'success',
                false: 'danger'
            },
            filter: {
                title: '',
                currency: 'try'
            }
        };
    },
    computed: {
        ...mapGetters({
            banks: 'getBanks'
        })
    },
    methods: {
        tableRowClassName({ row, rowIndex }) {
            if (!row.status) {
                return 'table-danger';
            }
            return '';
        },
        addNew() {
            self.modal = {
                status: true,
                loading: false,
                data: _.cloneDeep(emptyData)
            };
        },
        handleEdit(row) {
            self.modal = {
                status: true,
                loading: false,
                data: _.cloneDeep(row)
            };
        },
        handleDelete(id) {
            this.$swal({
                title: self.$i18n.t('quest.sure'),
                text: self.$i18n.t('quest.delete_info'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f5365c',
                confirmButtonText: self.$i18n.t('quest.delete'),
                cancelButtonText: self.$i18n.t('quest.cancel')
            }).then(result => {
                if (result.value) {
                    self.loading = true;
                    self.$store
                        .dispatch(BANK_DELETE, id)
                        .then(resp => {
                            self.loading = false;
                            self.getData();
                            self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                        })
                        .catch(err => {
                            self.loading = false;
                            handleError(self, err);
                        });
                }
            });
        },
        getData() {
            self.loading = true;
            self.$store
                .dispatch(BANK_REQUEST, { filter: self.filter })
                .catch(err => {
                    handleError(self, err);
                })
                .finally(() => {
                    self.loading = false;
                });
        },
        onSubmit() {
            if (self.modal.data.id) {
                self.onUpdate();
            } else {
                self.onAdd();
            }
        },
        onAdd() {
            let data = _.cloneDeep(self.modal.data);
            self.modal.loading = true;
            self.$store
                .dispatch(BANK_ADD, data)
                .then(() => {
                    self.modal.loading = false;
                    self.modal.status = false;
                    self.getData();
                })
                .catch(err => {
                    self.modal.loading = false;
                    handleError(self, err);
                });
        },
        onUpdate() {
            let data = _.cloneDeep(self.modal.data);
            let id = data.id;
            self.modal.loading = true;
            self.$store
                .dispatch(BANK_UPDATE, { id, bank: data })
                .then(() => {
                    self.modal.loading = false;
                    self.modal.status = false;
                    self.getData();
                })
                .catch(err => {
                    self.modal.loading = false;
                    handleError(self, err);
                });
        },
        searchTimeOut() {
            if (self.timer) {
                clearTimeout(self.timer);
                self.timer = null;
            }
            self.timer = setTimeout(() => {
                self.getData();
            }, 800);
        }
    },
    filters: {},
    created() {
        self = this;
        self.getData();
    }
};
</script>
